.form-control::-webkit-input-placeholder {
  color: theme-color("default");
  font-style: italic;
}
.form-control:-moz-placeholder {
  font-style: italic;
  color: theme-color("default");
}
.form-control::-moz-placeholder {
  font-style: italic;
  color: theme-color("default");
}
.form-control::placeholder {
  font-style: italic;
  color: theme-color("default");

  opacity: 1;
}
.form-control:-ms-input-placeholder {
  font-style: italic;
  color: theme-color("default");
}
.form-control {
  box-shadow: none !important;
  border-radius: $border-radius;
  padding: 0px !important;
  min-height: 30px;
  background: theme-color("trans");
  height: auto;
  font-weight: 500;
  color: black;
  color: $dark;
  font-size: 1rem;
  border: none !important;

  &:focus {
    background-color: transparent;
    color: black;

    &::placeholder {
      color: theme-color-level(light, -2) !important;
      opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder {
      /* Internet Explorer 2-11 */
      color: theme-color-level(light, -2) !important;
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: theme-color-level(light, -2) !important;
    }
  }
}
.form-control:disabled,
.form-control[readonly] {
  background-color: transparent;
  opacity: 1;
}
.big-shadow {
  box-shadow: 0px 30px 96px -30px #84737394;
}
label {
  transition: 400ms;
  font-weight: 500;
  margin: 0;
  text-align: left;
  padding: 0px;
  width: 100%;

  span.label-text {
    font-size: 14px;

    color: theme-color("default");
  }

  .valid-icon {
    color: theme-color("light") !important;
  }
  &.has-api-error {
    border-left-color: theme-color("danger");
  }

  &:focus-within,
  &:focus {
    // border-left: 6px solid theme-color("primary");

    &.has-error {
      border-left-color: theme-color("danger");
    }

    span.label-text {
      // color: theme-color-level(secondary, 2) !important;
    }

    .valid-icon {
      &.text-danger {
        color: theme-color("danger") !important;
      }
      &.text-success {
        color: theme-color("primary") !important;
      }
    }
  }
}
.form-group {
  // border-left: 1px solid theme-color("primary");
}
.input-group {
  // border: 1px solid #eaeaea;
  //box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  padding-left: 0px;
  //background: #fbfafa;
  background: theme-color("light");
  border-radius: $border-radius;
}
.input-group {
  &:hover,
  &:focus-within {
    box-shadow: 0px 1px 3px -2px #2d2d2d;
  }
}

.readonly {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  label {
    width: 30%;
    margin: 0;
  }

  .input-group {
    width: 70%;
  }
  .form-control {
    background: transparent !important;

    box-shadow: none !important;
    width: 100%;
  }
}

.input-group .input-group-append {
  background: f3f3f3;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border: none;
  display: flex;
  align-items: center;
  font-style: italic;
  color: #ababab;
}
textarea:focus,
textarea.form-control:focus,
input.form-control:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
input[type="number"]:focus,
[type="text"].form-control:focus,
[type="password"].form-control:focus,
[type="email"].form-control:focus,
[type="tel"].form-control:focus,
[contenteditable].form-control:focus {
  // box-shadow: 0px 1px 0px 0px theme-color("light") !important;
  border: 1px solid theme-color("success");
  background-color: transparent;
}

// .custom-switch {
//   padding-left: 3rem;
// }
// .custom-switch .custom-control-label {
//   color: theme-color("primary");
//   &:focus {
//     box-shadow: none;
//   }
//   &:before {
//     left: -3rem;
//     width: 40px;
//     height: 22px;
//   }
//   &:after {
//     width: 17px;
//     height: 17px;
//     left: -46px;
//   }
//   &:before,
//   &:after {
//     border-radius: 30px;
//     top: 50%;
//     transform: translateY(-50%);
//   }

//   &:after {
//     transform: translate(0px, -50%) !important;
//   }
// }
// .custom-control-input:focus ~ .custom-control-label::before {
//   box-shadow: none;
//   border: 1px solid theme-color("primary");
// }
// .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
//   border: 1px solid theme-color-level(primary, -8);
// }
// .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
//   background-color: theme-color("primary");
//   transform: translate(19px, -50%) !important;
// }
// .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
//   color: #fff;
//   background-color: theme-color-level(primary, -8);
//   border-color: theme-color-level(primary, -8);
// }
// .custom-control-input:checked ~ .custom-control-label::before {
//   color: #fff;
//   border-color: theme-color-level(primary, -8);
//   background: theme-color-level(primary, -8);
// }
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px white inset !important;
}

.ac-results .ac-res {
  background: white;
}
.ac-results .ac-res:hover {
  background-color: theme-color("default") !important;
}
.custom-range:focus {
  &::-webkit-slider-thumb {
    box-shadow: none !important;
    background: theme-color("secondary");
  }
  &::-moz-range-thumb {
    box-shadow: none !important;
    background: theme-color("secondary");
  }
  &::-ms-thumb {
    box-shadow: none !important;
    background: theme-color("secondary");
  }
}
.custom-range::-webkit-slider-thumb {
  background: theme-color("secondary");
  margin-top: -11px;
  height: 25px;
  width: 25px;
  border: 3px solid white;
  box-shadow: 0 1px 4px 1px rgb(202, 201, 201);
  &:focus {
    box-shadow: none;
    background: transparent;
  }
}
.custom-range::-moz-range-thumb {
  background: theme-color("secondary");
}

.custom-range::-ms-thumb {
  background: theme-color("secondary");
}

.custom-range::-webkit-slider-runnable-track {
  background-color: theme-color("light");
  height: 3px;
}
.custom-range::-moz-range-track {
  background-color: theme-color("light");
}
.custom-range::-ms-track {
  background-color: theme-color("light");
}
