@import "../../../../theme/colors.scss";

#sidebar {
  .nav-link {
    position: relative;
    line-height: 30px;

    &::after {
      content: "";
      position: absolute;
      bottom: -3px;
      left: 0;
      height: 2px;
      background: white;
      width: 100%;
      transform: scale(0);
      transition: 400ms;
    }

    &:hover,
    &[data-attr="active"] {
      &::after {
        transform: scale(1);
      }
    }
  }
}
