$theme-colors: (
  "primary": #dc911c,
  "secondary": #dc911c,
  "tertiary": #292c2f,
  "success": #02a30f,
  "info": #274c9f,
  "warning": #a56d02,
  "dark": #000000,
  "danger": #ad2736,
  "light": #edf2f5,
  "default": #3b3b3b,
  "trans": rgba(0, 0, 0, 0),
  "input": #a8a8a8,
);

$border-radius: 0px;
$ligthtingIntensity: 10%;
$darkeningIntensity: 10%;
$body: #edf2f5;
