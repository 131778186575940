.custom-table {
  overflow: hidden;
  box-shadow: none !important;

  th {
    font-size: 14px;
    color: #b7b7b7;
    padding: 10px 0;
    border-bottom: 0;
  }

  td {
    font-size: 15px;
    font-weight: 500;
  }
}
.table-small-padding {
  font-family: system-ui;
  font-size: 13px;
  td {
    font-size: 12px;
    padding: 5px !important;
  }
}
